<template>
	<div class="page-group-box">
		<div class="page-group-title">按场次设置退票规则</div>
		<el-form>			
			<el-form-item prop="refundRuleType" label="是否开放条件退:">
				<el-radio-group v-model="formData.refundRuleType">
					<el-radio :label="1">不开放</el-radio>
					<el-radio :label="3">开放，对各个场次进行设置</el-radio>
					<el-radio :label="2" disabled>开放，对整场演出进行设置,暂时无法编辑</el-radio>
				</el-radio-group>
			</el-form-item>
			<template v-if="formData && formData.refundRuleType == 3 ">
				<div class="color-danger">购票时间限制：用户在对应时间段内购票才能申请退票，不设置视为不对购票时间做要求。<br>以下每个场次最多设置5个扣费阶段，通票以最晚开演场次的规则为准。
				</div>
				<table class="info-table MT20" width="100%">
					<thead>
						<tr>
							<th>场次</th>
							<th>开演时间</th>
							<th>购票时间限制</th>
							<th>阶段1</th>
							<th>阶段2</th>
							<th>阶段3</th>
							<th>阶段4</th>
							<th>阶段5</th>
							<th>操作</th>
						</tr>
					</thead>
					<template v-if="formData && formData.sessionRefundRules">
						<tr v-for="(t, index) in formData.sessionRefundRules" :key="t.sessionId">
							<td>{{t.sessionName}}</td>
							<td>{{t.sessionStartTime}}</td>
							<td class="color-danger hand" @click="addBuyTime(index)">
								<template v-if="t.buyTimeStart || t.buyTimeEnd">
									<p v-if="t.buyTimeStart">起：{{ t.buyTimeStart }}</p>
									<p v-if="t.buyTimeEnd">止：{{ t.buyTimeEnd }}</p>
									<p class="font10">在以上时间段内购票才允许退票</p>
								</template>
								<div v-else class="color-blue" @click="addBuyTime(index)">
									暂未设置
								</div>
							</td>
							<td class="color-blue hand" @click="addTime(index,0)">
								<template v-if="t.refundRules[0]">
									<p v-if="t.refundRules[0].startTime">起：{{ t.refundRules[0].startTime }}</p>
									<p v-if="t.refundRules[0].endTime">止：{{ t.refundRules[0].endTime }}</p>
									<p class="font10">在以上时间段内申请退票，扣手续费：{{ t.refundRules[0].serviceFeeRatio }}%</p>
								</template>
								<div v-else class="color-blue">
									暂未设置
								</div>
							</td>
							<td class="color-blue hand" @click="addTime(index,1)">
								<template v-if="t.refundRules[1]">
									<p v-if="t.refundRules[1].startTime">起：{{ t.refundRules[1].startTime }}</p>
									<p v-if="t.refundRules[1].endTime">止：{{ t.refundRules[1].endTime }}</p>
									<p class="font10">在以上时间段内申请退票，扣手续费：{{ t.refundRules[1].serviceFeeRatio }}%</p>
								</template>
								<div v-else @click="addTime(index,1)">
									暂未设置
								</div>
							</td>
							<td class="color-blue hand" @click="addTime(index,2)">
								<template v-if="t.refundRules[2]">
									<p v-if="t.refundRules[2].startTime">起：{{ t.refundRules[2].startTime }}</p>
									<p v-if="t.refundRules[2].endTime">止：{{ t.refundRules[2].endTime }}</p>
									<p class="font10">在以上时间段内申请退票，扣手续费：{{ t.refundRules[2].serviceFeeRatio }}%</p>
								</template>
								<div v-else @click="addTime(index,2)">
									暂未设置
								</div>
							</td>
							<td class="color-blue hand" @click="addTime(index,3)">
								<template v-if="t.refundRules[3]">
									<p v-if="t.refundRules[3].startTime">起：{{ t.refundRules[3].startTime }}</p>
									<p v-if="t.refundRules[3].endTime">止：{{ t.refundRules[3].endTime }}</p>
									<p class="font10">在以上时间段内申请退票，扣手续费：{{ t.refundRules[3].serviceFeeRatio }}%</p>
								</template>
								<div v-else @click="addTime(index,3)">
									暂未设置
								</div>
							</td>
							<td class="color-blue hand" @click="addTime(index,4)">
								<template v-if="t.refundRules[4]">
									<p v-if="t.refundRules[4].startTime">起：{{ t.refundRules[4].startTime }}</p>
									<p v-if="t.refundRules[4].endTime">止：{{ t.refundRules[4].endTime }}</p>
									<p class="font10">在以上时间段内申请退票，扣手续费：{{ t.refundRules[4].serviceFeeRatio }}%</p>
								</template>
								<div v-else @click="addTime(index,4)">
									暂未设置
								</div>
							</td>
							<td class="color-blue hand" v-if="formData.sessionRefundRules.length > 1"><span
									class="el-icon-edit" @click="batchSet(index)">以我为准，批量设置其他场次</span></td>
						</tr>
					</template>
				</table>
			</template>
		</el-form>
		<el-dialog title="退费手续费" :visible.sync="dialogVisible" width="500px" center>
			<div class="dialog-content">
				<el-form :model="copyInfo" ref="copyInfo">
					<el-form-item prop="startTime" label="起始时间:" :rules="[{required: true, message: '请输入起始时间'}]">						
						<el-date-picker
						  size="small"
						  v-model="copyInfo.startTime"
						  type="datetime"
						  placeholder="起始时间">
						</el-date-picker>					
					</el-form-item>
					<el-form-item prop="endTime" label="结束时间:"  :rules="[{required: true, message: '请输入结束时间'}]">						
						<el-date-picker
						  size="small"
						  v-model="copyInfo.endTime"
						  type="datetime"
						  placeholder="结束时间">
						</el-date-picker>
					</el-form-item>
					<el-form-item prop="ratio" label="扣除手续费比例:"  :rules="[{required: true, message: '请输入扣除手续费比例'}]">
						<el-input-number style="width:30%;" v-model="copyInfo.ratio" :min="0" :max="99"
						    :controls="false" :precision='0'></el-input-number> %
							<small class="color-danger">精确到1%，最低0%，最高99%，5个阶段以外的时间均视为不支持退票。</small>
					</el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<div style="display: flex; align-items: center;">
					<el-button type="primary" @click="addTimefun()">确 定</el-button>
					<div style="text-align: left; margin-left: 10px;"><small class="color-danger">请检查是否存在空缺时间段<br>用户无法在未设置的时间段申请退票</small></div>
				</div>				
			</span>
		</el-dialog>
		<el-dialog title="购票时间限制" :visible.sync="BuydialogVisible" width="500px" center>
			<div class="dialog-content">
				<el-form :model="copybuyInfo" ref="copybuyInfo">
					<el-form-item prop="buyTimeStart" label="起始时间:" :rules="[{required: true, message: '请输入起始时间'}]">						
						<el-date-picker
						  size="small"
						  v-model="copybuyInfo.buyTimeStart"
						  type="datetime"
						  placeholder="起始时间">
						</el-date-picker>					
					</el-form-item>
					<el-form-item prop="buyTimeEnd" label="结束时间:"  :rules="[{required: true, message: '请输入结束时间'}]">						
						<el-date-picker
						  size="small"
						  v-model="copybuyInfo.buyTimeEnd"
						  type="datetime"
						  placeholder="结束时间">
						</el-date-picker>
					</el-form-item>					
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<div style="display: flex; align-items: center;">
					<el-button type="primary" @click="addbuytimeFun()">确 定</el-button>
				</div>				
			</span>
		</el-dialog>
	</div>
</template>
<script>
	export default {
		props: {
			formData: {
				type: Object,
				default: null
			},
			isEdit:{
				type:Boolean,
				default:false
			}
		},
		data() {
			return {
				copyInfo: {
					num:0,
					index:0,
					startTime:null,
					endTime:null,
					ratio:null,
				},
				copybuyInfo:{
					index:0,
					buyTimeStart:null,
					buyTimeEnd:null,
				},
				pickerOptions:{
				    disabledDate(time) {
				        return time.getTime() < Date.now();
				    },
				},
				num:'',
				timeIndex:'',
				dialogVisible:false,
				BuydialogVisible:false,
			}
		},
		computed: {

		},
		methods: {
			addTime(num,index){				
				this.copyInfo.num = num
				this.copyInfo.index = index
				this.copyInfo.startTime = null
				this.copyInfo.endTime = null
				this.copyInfo.ratio = null		
				
				if( this.formData.sessionRefundRules[num].refundRules[index] != null){
					var refundRules = this.formData.sessionRefundRules[num].refundRules[index]				
					this.copyInfo.startTime = refundRules.startTime
					this.copyInfo.endTime = refundRules.endTime
					this.copyInfo.ratio = refundRules.serviceFeeRatio
				}else{
					this.copyInfo.startTime = ''
					this.copyInfo.endTime = ''
					this.copyInfo.ratio = 0
				}
				this.dialogVisible = true;			
			},
			addTimefun(){
				this.$refs["copyInfo"].validate((valid) => {
					if (valid) {
						var startTime = this.$utils.formatDate(this.copyInfo.startTime, 'yyyy-MM-dd HH:mm:ss')
						var endTime = this.$utils.formatDate(this.copyInfo.endTime, 'yyyy-MM-dd HH:mm:ss')
						
						
						if (this.$utils.compareDate(startTime, endTime)) {
						    this.$message.error('开始时间必须小于结束时间');
						    return;
						}
						var refundRules = {}
						refundRules.startTime = startTime
						refundRules.endTime = endTime
						refundRules.serviceFeeRatio = this.copyInfo.ratio
						this.$set(this.formData.sessionRefundRules[this.copyInfo.num].refundRules,this.copyInfo.index,refundRules)					
						this.dialogVisible = false;					
						
						document.documentElement.scrollTop = document.body.scrollTop = 0;
						
						this.$emit('update:isEdit', true);
					}
					
				})
			},
			addBuyTime(index){
				
				this.BuydialogVisible = true;
				this.copybuyInfo.startTime = null
				this.copybuyInfo.endTime = null
				this.copybuyInfo.index = index
				
				if( this.formData.sessionRefundRules[index] ){
					var refundRules = this.formData.sessionRefundRules[index]		
					this.copybuyInfo.buyTimeStart = refundRules.buyTimeStart
					this.copybuyInfo.buyTimeEnd = refundRules.buyTimeEnd
				}else{
					this.copybuyInfo.buyTimeStart = ''
					this.copybuyInfo.buyTimeEnd = ''
				}
			},
			addbuytimeFun(){
				this.$refs["copybuyInfo"].validate((valid) => {
					if (valid) {
						var startTime = this.$utils.formatDate(this.copybuyInfo.buyTimeStart, 'yyyy-MM-dd HH:mm:ss')
						var endTime = this.$utils.formatDate(this.copybuyInfo.buyTimeEnd, 'yyyy-MM-dd HH:mm:ss')
						
						
						if (this.$utils.compareDate(startTime, endTime)) {
						    this.$message.error('开始时间必须小于结束时间');
						    return;
						}
						
						this.formData.sessionRefundRules[this.copybuyInfo.index].buyTimeStart = startTime
						this.formData.sessionRefundRules[this.copybuyInfo.index].buyTimeEnd = endTime
						
						this.BuydialogVisible = false;
						document.documentElement.scrollTop = document.body.scrollTop = 0;
						this.$emit('update:isEdit', true);
					}
					
				})
			},
			batchSet(index){
				this.$confirm(`是否确认以该场次的设置为准，批量设置其他场次?<br><span class="color-danger">确认后，仍然需要提交整个表单才会生效。</span>`, '批量设置其他场次', {			
				center: false,
				closeOnClickModal: false,
				dangerouslyUseHTMLString: true,	
				}).then(() => {
					var refundRulesData = this.formData.sessionRefundRules[index];					
					this.formData.sessionRefundRules.forEach((item, index) => {
						this.formData.sessionRefundRules[index].refundRules = [...refundRulesData.refundRules]
						this.formData.sessionRefundRules[index].buyTimeStart = refundRulesData.buyTimeStart
						this.formData.sessionRefundRules[index].buyTimeEnd = refundRulesData.buyTimeEnd
						
					})
					this.$emit('update:isEdit', true);
				}).catch(() => {})
			},
		}
	}
</script>
<style lang="scss" scoped>
.hand{
	cursor: pointer;
}
.info-table tr th{
	text-align: left;
}
td{
	border-right:1px solid #eee;
}
td:last-child{
	
}
</style>