<template>
    <div class="page-group-box">
        <div class="page-group-title">
            通票列表
            <span class="color-regular"><i class="el-icon-info"></i> 关于通票:
                如果需要售卖通票（多个场次均可使用），请联系秀动客服。经营平台暂不支持创建通票，敬请谅解</span>
        </div>
        <el-table class="MT20" :data="throughTickets" style="width: 100%" size="small"
            header-row-class-name="page-list-table">
            <el-table-column prop="ticketName" label="名称" min-width="100" show-overflow-tooltip> </el-table-column>
            <el-table-column label="类型">
                <template slot-scope="scope">
                    <span v-if="scope.row.groupId == 1">普通门票</span>
                    <span v-else-if="scope.row.groupId == 2">车票</span>
                </template>
            </el-table-column>
            <el-table-column prop="memberNum" label="可进场人数" min-width="100">
                <template slot-scope="scope">{{ memberNum[scope.row.memberNum] }}</template>
            </el-table-column>
            <el-table-column label="销售状态">
                <template slot-scope="scope">
                    <span v-if="scope.row.status == 0" class="color-success">正常销售</span>
                    <span v-if="scope.row.status == 2" class="color-warning">优惠码销售</span>
                    <span v-if="scope.row.status == 1" class="color-info">暂停销售</span>
                </template>
            </el-table-column>
            <el-table-column prop="sellPrice" label="售价" min-width="90">
                <template slot-scope="scope">
                    {{ parseFloat(scope.row.sellPrice).toFixed(2) }}
                </template>
            </el-table-column>
            <el-table-column prop="originalPrice" label="原价" min-width="90">
                <template slot-scope="scope">
                    {{ parseFloat(scope.row.originalPrice).toFixed(2) }}
                </template>
            </el-table-column>
            <el-table-column prop="accountLimitNum" label="账号限购">
                <template slot-scope="scope">
                    {{ scope.row.accountLimitNum == 0 ? '不限购' : scope.row.accountLimitNum }}
                </template>
            </el-table-column>
            <el-table-column prop="totalNum" label="总库存(票)"></el-table-column>
            <el-table-column prop="sellNum" label="已销售(票)">
                <template slot-scope="scope">
                    {{ scope.row.sellNum || 0 }}
                </template>
            </el-table-column>
            <el-table-column prop="occupyNum" label="待支付(票)">
                <template slot-scope="scope">
                    {{ scope.row.occupyNum || 0 }}
                </template>
            </el-table-column>
            <el-table-column label="剩余(票)">
                <template slot-scope="scope">
                    {{ scope.row.totalNum - (scope.row.sellNum || 0) - (scope.row.occupyNum || 0) }}
                </template>
            </el-table-column>
            <el-table-column prop="refundNum" label="已退(票)">
                <template slot-scope="scope">
                    {{ scope.row.refundNum || 0 }}
                </template>
            </el-table-column>
            <el-table-column label="销售时间" min-width="170">
                <template slot-scope="scope">
                    <p v-if="scope.row.startTime">起:{{ scope.row.startTime }}</p>
                    <p v-if="scope.row.endTime">止:{{ scope.row.endTime }}</p>
                </template>
            </el-table-column>
            <!-- <el-table-column label="验票时间" min-width="160">
                    <template slot-scope="scope">
                        <p v-if="scope.row.validStartTime">起:{{scope.row.validStartTime}}</p>
                        <p v-if="scope.row.validEndTime">止:{{scope.row.validEndTime}}</p>
                    </template>
                </el-table-column> -->
            <el-table-column prop="instruction" label="票品说明" min-width="100" show-overflow-tooltip></el-table-column>

        </el-table>
    </div>
</template>
<script>
export default {
    props:{
        throughTickets:{
            type: Array,
            default: () => []
        }
    },
    data() {
        return {

        }
    },
    computed:{
        memberNum(){
            return{
                1: '单人',
                2: '双人',
                3: '三人',
                4: '四人',
                5: '五人',
                6: '六人',
                7: '七人',
                8: '八人',
                9: '九人',
                10: '十人'
            }
        }
    },
}
</script>
<style lang="scss" scoped></style>