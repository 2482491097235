<template>
    <div>
        <el-tabs v-model="activeName" @tab-click="clickEvent">
            <el-tab-pane label="基本信息" name="1"></el-tab-pane>
            <el-tab-pane label="场次设置" name="2"></el-tab-pane>
            <el-tab-pane label="通票查看" name="3"></el-tab-pane>
			<el-tab-pane label="退票规则" name="4"></el-tab-pane>
        </el-tabs>
    </div>
</template>
<script>
export default {
    props:{
        tabName:{
            type: String,
            default: '1'
        }
    },
    data(){
        return{
            activeName: this.tabName,
        }
    },
    methods:{
        clickEvent(){
            this.$emit("update:tabName", this.activeName)
        }
    }
}
</script>

<style lang="scss" scoped>
// .el-tabs{
//    ::v-deep .el-tabs__header{
//        border: 0;
//        .el-tabs__nav{
//            border-bottom: 1px solid #E4E7ED;
//            border-radius: 4px;
//            overflow: hidden;
//            .el-tabs__item.is-active{
//                border-bottom: 0;
//                background: $--color-theme;
//                color: #ffffff;
//            }
//        }
//    }
// }
</style>